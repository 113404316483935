import React from "react";
import styles from "./styles.module.css";
import { Link } from "gatsby";

export default function CourrielClasseAffaires() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Courriel classe affaire</h1>
        <div className={styles.modernbricksmenu2}>
          <ul>
            <li>
              <Link to="/developpez/services-web/solutions-pour-le-cybercommerce/forfaits-cybercommerce/">
                Courriel classe affaires
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/forfaits-courriels/organisez/">
                Organisez
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/forfaits-courriels/synchronisez/">
                Synchronisez
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/hebergement-web/">
                Forfaits Web
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/forfaits-courriels/">
                Forfaits courriel
              </Link>
            </li>
          </ul>
        </div>
        <p>
          Business Mail Pro en association avec SyncSuite : les outils dont vous
          et vos collègues avez besoin pour être bien organisés et rester
          connectés les uns aux autres.
        </p>
      </div>

      <div className={[styles.row, styles.tableWrapper].join(" ")}>
        <table width="100%" border="0" cellspacing="5" cellpadding="5">
          <thead className={styles.tableHeader}>
            <tr>
              <th colSpan="2">
                <h2>Caractéristiques du Courriel classe affaires</h2>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Capacité de stockage de la boîte de courriel </td>
              <td>2 Go </td>
            </tr>
            <tr>
              <td>POP/SMTP*</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Webmail*</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>IMAP / courriel conservé sur le serveur)*</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Courriel poussé et autoconfiguration BlackBerry </td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Filtre antipourriel</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Antivirus</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Autorépondeur</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Gestionnaire de courriel personnel</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Alias collecteur pour un nombre illimité d'adresses courriel
              </td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Pseudonymes</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Réacheminent des messages</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Limite de transfert de courriels</td>
              <td>Aucune</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan="2">
                <h2>
                  Outils collaboratifs améliorant l'organisation et la
                  productivité
                </h2>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Calendriers personnels et partagés </td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Carnets d’adresses personnels et partagés </td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Listes de tâches personnelles et partagées</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>Notes personnelles et partagées</td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" border="0" cellspacing="5" cellpadding="5">
          <thead>
            <tr>
              <th colSpan="2">
                <h2>
                  Synchronisation de vos données et dispositifs à travers
                  Internet
                </h2>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Synchronisation de vos calendriers et carnet d’adresses
                personnels
              </td>
              <td>
                <span class="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Synchronisation entre : </strong>
              </td>
              <td> </td>
            </tr>
            <tr>
              <td>Webmail</td>
              <td>Incluse</td>
            </tr>
            <tr>
              <td>Outlook</td>
              <td>Incluse</td>
            </tr>
            <tr>
              <td>Outlook Express </td>
              <td>Incluse</td>
            </tr>
            <tr>
              <td>BlackBerry</td>
              <td>Incluse</td>
            </tr>
            <tr>
              <td>Palm OS</td>
              <td>Incluse</td>
            </tr>
            <tr>
              <td>Dispositifs sans fil adaptés à SyncML</td>
              <td>Incluse</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
