import React from "react";
import Layout from "../../../../other/layout";
import CourrielClasseAffaires from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/ForfaitsCourriels/CourrielClasseAffaires";
import SEO from "../../../../other/seo";

export default function CourrielClasseAffairesPage() {
  return (
    <Layout>
      <SEO title="Synchronisez vos données et demeurez à jour" />
      <CourrielClasseAffaires />
    </Layout>
  );
}
